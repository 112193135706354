import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Hero from "../components/whoweare-page/hero/Hero.js";
import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import Description from "../components/whoweare-page/description/Description.js";
import DescriptionBis from "../components/whoweare-page/description-bis/DescriptionBis.js";
import Research from "../components/whoweare-page/research/Research.js";
// import Values from "../components/whoweare-page/values/Values.js";
import Footprint from "../components/whoweare-page/footprint/Footprint.js";
// import Leadership from "../components/whoweare-page/leadership/Leadership.js";
// import Mission from "../components/whoweare-page/mission/Mission.js";

const WhowearePage = (props) => {
  const { strapiWhoWeAre } = useStaticQuery(query);

  return (
    <Layout path={props.location.pathname}>
      <Seo
        title={strapiWhoWeAre.metaTitle}
        description={strapiWhoWeAre.metaDescription}
        keywords={strapiWhoWeAre.metaKeywords}
      />
      <Hero path={props.location.pathname} />
      <Description />
      <DescriptionBis />
      <Research />
      {/* <Leadership /> */}
      {/* <Mission />
            <Values /> */}
      <Footprint />
    </Layout>
  );
};

export default WhowearePage;

const query = graphql`
  query {
    strapiWhoWeAre {
      metaTitle
      metaDescription
      metaKeywords
    }
  }
`;
