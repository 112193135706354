import React from "react";
import styled from "styled-components";
import researchImage from "../../../../static/images/whoweare-page/Research.svg";
import isosImg from "../../../../static/images/whoweare-page/isos.png";

const Research = () => {
  return (
    <>
      <ResearchMobileStyle>
        <TitleMobile>
          R&D & Innovative<span>Manufacturing in the UAE</span>
        </TitleMobile>
        <ListMobile>
          <ListItemMobile>
            We envision a future where the UAE is recognized globally as a
            vibrant hub of engineering and manufacturing excellence. We aim to
            be at the forefront of this transformation, with our portfolio of
            products being developed and manufactured in Abu Dhabi. Our
            state-of-the-art facility has research, design, rapid prototyping,
            PCB population and production capabilities, accompanied by locally
            and internationally accredited certifications to ensure quality.
          </ListItemMobile>

          <SmallImage img={isosImg} imgMobile={isosImg} className={"a"} />
        </ListMobile>
      </ResearchMobileStyle>
      <ResearchStyle>
        <Title>
          R&D & Innovative
          <br />
          Manufacturing in the UAE
        </Title>
        <List>
          <Description>
            We envision a future where the UAE is recognized globally as a
            vibrant hub of engineering and manufacturing excellence. We aim to
            be at the forefront of this transformation, with our portfolio of
            products being developed and manufactured in Abu Dhabi. Our
            state-of-the-art facility has research, design, rapid prototyping,
            PCB population and production capabilities, accompanied by locally
            and internationally accredited certifications to ensure quality.
          </Description>
          <SmallImage img={isosImg} imgMobile={isosImg} className={"a"} />
          {/* <ListItem>
            <SmallImage img={isosImg} imgMobile={isosImg} className={"a"} />
          </ListItem> */}
          {/* <ListItem>
            Gathering a team of research scientists, computer scientists,
            mathematicians, biochemists and engineers, the design and
            manufacturing of Integrated Chips (IC) and Printed Circuit Boards
            (PCB), as well as the development of firmware and software all take
            place in Bu Graphene.
          </ListItem>
          <ListItem>
            The R&D arm is focused on continuous research in AI, machine
            learning, programmatic intelligence, and embedded firmware
            engineering.
          </ListItem>
          <ListItem>
            Part of the recent achievements of Bu Graphene is the setup of a
            pilot plant that allows fast prototyping and testing of serial
            production in a lean manufacturing structure.
          </ListItem> */}
        </List>
      </ResearchStyle>
    </>
  );
};

export default Research;

const ResearchMobileStyle = styled.div`
  @media ${({ theme }) => theme.breakpoints.mobile_reverse} {
    display: none;
  }
`;

const TitleMobile = styled.div`
  min-height: calc(60vh);
  background-image: url(${researchImage});
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 2rem;
  line-height: 2.6rem;
  padding: 0rem 2.4rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  span {
    display: block;
  }
`;

const DescriptionMobile = styled.div`
  padding: 3rem 2.4rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: ${({ theme }) => theme.fonts.sequel45};
`;

const ListMobile = styled.ul`
  list-style-type: none;
  margin: 2rem 2.4rem;
  display: flex;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const ListItemMobile = styled.li`
  flex: 1 calc(50% - 8rem);
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: ${({ theme }) => theme.fonts.sequel45};
  margin: 0.5rem 0rem;
`;

const ResearchStyle = styled.div`
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: none;
  }
  min-height: calc(80vh);
  background-image: url(${researchImage});
  background-repeat: no-repeat;
  background-size: cover;

  background-attachment: fixed;
  background-position: center;

  color: ${({ theme }) => theme.colors.white};
  padding: 10rem 16rem 10rem 16rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 10rem 15vw 10rem 15vw;
  }
`;

const Title = styled.h3`
  flex: 1;
  // font-size: 2.0rem;
  // line-height: 2.6rem;
  margin-bottom: 3.5rem;
  font-family: ${({ theme }) => theme.fonts.sequel75};
`;

const Description = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  //margin-bottom: 2rem;
  font-family: ${({ theme }) => theme.fonts.sequel55};
`;

const List = styled.ul`
  list-style-type: none;
  flex: 1;
  margin: 0rem 4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-self: baseline;
  list-style-type: none;
`;

const ListItem = styled.li`
  // flex: 1 calc(50% - 8rem);
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.sequel45};
  font-weight: 400;
`;

const SmallImage = styled.div`
  margin-top: 5rem;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  height: 35.5rem;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    background-image: url(${(props) => props.imgMobile});
    height: 30.8rem;
    margin-top: 5.5rem;
  }
`;
