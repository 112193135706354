import React from "react";
import styled from "styled-components";

const Description = () => {
  return (
    <DescriptionStyle>
      <H2>
        Medad Technology is dedicated to developing novel therapeutic solutions
        that are smarter, safer and more effective for the prevention and
        treatment of chronic conditions. We are committed to improving public
        health and quality of life through scientific excellence and integrity.
      </H2>
      <P>
        After 4 years of extensive R&D and 300million AED in investment, Medad
        Technology successfully developed an ultrasonic delivery system that
        overcomes limitations of aerosolization. We are proudly established and
        headquartered in the UAE, with operations in both the US and the UK.
      </P>
    </DescriptionStyle>
  );
};

export default Description;

const DescriptionStyle = styled.div`
  padding: 10rem 16rem 10rem 16rem;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4.9rem;
  background: linear-gradient(
    11deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(149, 144, 217, 0.5) 100%
  );
  @media ${({ theme }) => theme.breakpoints.mobile} {
    min-height: auto;
    padding: 10rem 2.4rem 4rem 2.4rem;
    gap: 2rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 10rem 15vw 10rem 15vw;
  }
`;

const H2 = styled.h2`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: auto;
    font-size: 2rem;
    line-height: 2.7rem;
  }
`;

const P = styled.p`
  font-family: Sequel45;
  font-size: 1.8rem;
  line-height: 2.4rem;
  span {
    display: block;
    margin-top: 3rem;
  }
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: auto;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;
