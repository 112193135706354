import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, navigate } from "gatsby";
import bg from "../../../../static/images/whoweare-page/RandD.png";
// import ButtonStyled from "../../library/Button.js";

const DescriptionBis = () => {
  const { strapiHomePage } = useStaticQuery(query);

  return (
    <>
      <DescriptionBisStyle>
        <Left></Left>
        <Right>
          <Description>
            Amongst our esteemed research & development partners, we are working
            with one of the world's leading medical establishments, Mayo Clinic,
            to further develop our aerosolization technology.
          </Description>
          <Description>
            As part of our collaboration, the Mayo Foundation for Medical
            Education & Research are providing research support and conducting
            peer-reviewed research studies to improve our technology, validate
            its performance and advance our understanding of ultrasonic aerosol
            generation.
          </Description>
        </Right>
      </DescriptionBisStyle>
      <DescriptionWrapper></DescriptionWrapper>
    </>
  );
};

const DescriptionBisStyle = styled.div`
  min-height: calc(50vh);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-content: center;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const Left = styled.div`
  min-height: 55vh;
  background-image: url(${bg});

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    min-height: 60vh;
  }
`;

const Right = styled.div`
  padding: 10rem;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 3rem 2.4rem 0rem 2.4rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 10rem 10vw 10rem 10vw;
  }
`;

const Description = styled.p`
  padding: 3.2rem 0;
  font-size: 1.8rem;
  font-family: Sequel45;
  line-height: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 1rem 0;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-family: ${({ theme }) => theme.fonts.sequel45};
  }
`;

const DescriptionWrapper = styled.div`
  padding: 6.5rem 12rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 1rem 2.4rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 6.5rem 15vw;
  }
`;

export default DescriptionBis;

const query = graphql`
  query {
    strapiHomePage {
      title
      subtitle
    }
  }
`;
