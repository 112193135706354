import React from 'react'
import styled from 'styled-components'
import bg from "../../../../static/images/whoweare-page/Fooprint.jpg"
import bgMobile from "../../../../static/images/whoweare-page/Footprint-mobile.png"
import bgLocation from "../../../../static/images/whoweare-page/Footprint-location.svg"

const Footprint = () => {
    return (<FootprintWrapper>
            <FootprintStyleMobile>
                <Title>OUR FOOTPRINT</Title>
                <BlackBG>
                    <Img/>
                    <Text>
                        <ImgLocation/>
                        <TextWrapper>
                            <Location>UAE</Location>
                            <Description><span>Research </span><span>Design & Engineering </span> <span>Product Development </span>
                                <span>Distribution </span></Description>
                        </TextWrapper>
                    </Text>
                    <Text>
                        <ImgLocation/>
                        <TextWrapper>
                            <Location>Ireland</Location>
                            <Description><span>Distribution</span></Description>
                        </TextWrapper>
                    </Text>
                    <Text>
                        <ImgLocation/>
                        <TextWrapper>
                            <Location>US</Location>
                            <Description><span>Research </span><span>Design & Engineering </span> <span>Product Development </span>
                                <span>Distribution </span></Description>
                        </TextWrapper>
                    </Text>
                </BlackBG>
            </FootprintStyleMobile>
            <FootprintStyle/>
        </FootprintWrapper>
    )
}

export default Footprint

const FootprintStyleMobile = styled.div`
  @media ${({theme}) => theme.breakpoints.mobile_reverse} {
    display: none;
  }
`

const FootprintWrapper = styled.div`
  margin-bottom: 4rem;
`

const ImgLocation = styled.div`
  height: 40px;
  width: 40px;
  background-image: url(${bgLocation});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex: 0.2;
`

const Location = styled.p`
  color: #3B869D;
`

const TextWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 0rem 2.4rem;
  flex: 1;
`

const Description = styled.p`
  span {
    display: block;
    margin-bottom: 0.8rem;
  }
`

const Title = styled.h2`
  font-size: 3.2rem;
  padding: 3rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 2rem;
  }
`

const BlackBG = styled.div`
  background-color: rgb(5, 15, 17);
  flex: 0.2;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding-bottom: 7.4rem;
  }
`

const Img = styled.div`
  min-height: 70vh;
  background-image: url(${bgMobile});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`

const Text = styled.div`
  color: ${({theme}) => theme.colors.white};
  line-height: 2.7rem;
  padding: 3rem 2.4rem 0rem 2.4rem;
  display: flex;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    line-height: 2.2rem;
  }
`

const FootprintStyle = styled.div`
  height: 100vh;
  min-height: 120rem;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${({theme}) => theme.breakpoints.mobile} {
    display: none;
  }
`
